import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'gatsby';
import Container from 'react-bootstrap/Container';

if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  require('smooth-scroll')('a[href*="#"]');
}

const Header = () => (
  <section style={{ backgroundColor: '#532A12' }}>
    <Container>
      <Navbar expand="lg" data-cy="menu">
        <Link to="/">
          <Navbar.Brand>Čukoláda</Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Link to="/obchod" className="nav-link" role="button">
              ZÁKUSKY / DEZERTY
            </Link>
            <Link to="/menu" className="nav-link" role="button">
              MENU
            </Link>
            <Link to="/" className="nav-link" role="button">
              O NÁS
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  </section>
);

export default Header;
