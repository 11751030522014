import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { graphql, StaticQuery } from 'gatsby';
import GoogleMaps from '../map';
import facebookIcon from '../../images/facebook.svg';
import instagramIcon from '../../images/instagram.svg';

const Footer = () => (
  <StaticQuery
    query={graphql`
      query Hours {
        allAboutJson {
          nodes {
            mo
            tu
            we
            th
            fr
            sa
            su
          }
        }
      }
    `}
    render={(data) => (
      <section id="contact" className="footer">
        <Container>
          <Row className="pt-5 pb-4">
            <Col md={3}>
              <h2>ADRESA</h2>
              <p>Čukoláda</p>
              <p>Hlavná 89</p>
              <p>Prešov</p>
            </Col>
            <Col md={5}>
              <h2>OTVÁRACIE HODINY</h2>
              <table cellSpacing="0" cellPadding="0">
                <tr itemProp="openingHours">
                  <td className="day-style">Po</td>
                  <td colSpan={2} className="time-style">
                    {data.allAboutJson.nodes[0].mo}
                  </td>
                </tr>
                <tr itemProp="openingHours">
                  <td className="day-style">Ut</td>
                  <td colSpan={2} className="time-style">
                    {data.allAboutJson.nodes[0].tu}
                  </td>
                </tr>
                <tr itemProp="openingHours">
                  <td className="day-style">St</td>
                  <td colSpan={2} className="time-style">
                    {data.allAboutJson.nodes[0].we}
                  </td>
                </tr>
                <tr itemProp="openingHours">
                  <td className="day-style">Št</td>
                  <td colSpan={2} className="time-style">
                    {data.allAboutJson.nodes[0].th}
                  </td>
                </tr>
                <tr itemProp="openingHours">
                  <td className="day-style">Pia</td>
                  <td colSpan={2} className="time-style">
                    {data.allAboutJson.nodes[0].fr}
                  </td>
                </tr>
                <tr itemProp="openingHours">
                  <td className="day-style">So</td>
                  <td colSpan={2} className="time-style">
                    {data.allAboutJson.nodes[0].sa}
                  </td>
                </tr>
                <tr itemProp="openingHours">
                  <td className="day-style">Ne</td>
                  <td colSpan={2} className="time-style">
                    {data.allAboutJson.nodes[0].su}
                  </td>
                </tr>
              </table>
            </Col>
            <Col md={4}>
              <h2>MAPA</h2>
              <GoogleMaps latitude={48.9981759} longitude={21.23839} />
            </Col>
          </Row>
          <Row className="pb-5">
            <Col md={6}>
              <a
                className="pr-3"
                href="https://www.facebook.com/Cukolada/"
                target="_blank nooperner norefferer">
                <img src={facebookIcon} width={29} height={29} alt="Facebook" />
              </a>
              <a href="https://www.instagram.com/Cukolada/" target="_blank nooperner norefferer">
                <img src={instagramIcon} width={29} height={29} alt="Instagram" />
              </a>
            </Col>
            <Col md={6} style={{ textAlign: 'right' }}>
              <p>
                email:{' '}
                <a href="mailto:info@cukolada.sk" target="_top">
                  info@cukolada.sk
                </a>{' '}
                &nbsp; telefón:{' '}
                <a href="tel:+421915370833" target="_top">
                  0915 370 833
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    )}
  />
);

export default Footer;
