import React from 'react';
import GoogleMapReact from 'google-map-react';

const GoogleMaps = ({ latitude, longitude }) => {
  const renderMarkers = (map, maps) => {
    return new maps.Marker({
      position: { lat: latitude, lng: longitude },
      map,
      title: 'Hello World!',
    });
  };

  return (
    <div style={{ height: '25vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyBCFE6xD23aoFDLivJAWRRYr6p03fNYUlE' }}
        defaultCenter={{ lat: latitude, lng: longitude }}
        defaultZoom={16}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
      />
    </div>
  );
};

export default GoogleMaps;
